import Vue from 'vue'
import axios from 'axios'
import JwtService from '@/common/jwt.service'

const mainAxios = axios.create({
  baseURL: 'https://api.app.gigport.se', // 'https://api.test.app.gigport.se',
  timeout: '28000'
})

const authAxios = axios.create({
  baseURL: 'https://auth.test.app.gigport.se',
  timeout: '28000'
})

const ApiService = {
  init () {
    mainAxios.defaults.baseURL = window.location.protocol.concat('//api.').concat(window.location.hostname)
    mainAxios.defaults.baseURL = mainAxios.defaults.baseURL.replace('www.', '')

    if (window.location.hostname === 'localhost' || window.location.hostname.includes('192.168')) {
      mainAxios.defaults.baseURL = 'https://api.test.app.gigport.se'
    }

    authAxios.defaults.baseURL = window.location.protocol.concat('//auth.').concat(window.location.hostname)
    authAxios.defaults.baseURL = authAxios.defaults.baseURL.replace('www.', '')

    if (window.location.hostname === 'localhost' || window.location.hostname.includes('192.168')) {
      authAxios.defaults.baseURL = 'https://auth.test.app.gigport.se'
    }

    Vue.prototype.$http = mainAxios
    Vue.prototype.$authHttp = authAxios
  },
  setHeader () {
    mainAxios.defaults.headers.common.Authorization = JwtService.getToken()
  },
  deleteHeader () {
    delete mainAxios.defaults.headers.common.Authorization
  },
  getCompanies (callback) {
    return mainAxios.get('companies').then(res => {
      callback(null, res)
    }).catch(err => {
      console.error(err.response)
      callback(err, null)
    })
  },
  getProducts (callback) {
    return mainAxios.get('products').then(res => {
      callback(null, res)
    }).catch(err => {
      console.error(err.response)
      callback(err, null)
    })
  },
  getContracts (callback) {
    return mainAxios.get('contracts').then(res => {
      callback(null, res)
    }).catch(err => {
      console.error(err.response)
      callback(err, null)
    })
  },
  getCompanyDetails (companyId, callback) {
    return mainAxios.get(`company/${companyId}`).then(res => {
      callback(null, res)
    }).catch(err => {
      callback(err, null)
    })
  },
  saveCompanyDetails (companyDetails, callback) {
    return mainAxios.post(`company/${companyDetails.companyId}/update`, companyDetails).then(res => {
      callback(null, res)
    }).catch(err => {
      callback(err, null)
    })
  },
  getCompanyByLink (link, callback) {
    return mainAxios.get(`company/link/${link}`).then(res => {
      callback(null, res)
    }).catch(err => {
      callback(err, null)
    })
  },
  getCompanyInviteLink (companyId, callback) {
    return mainAxios.get(`company/${companyId}/link`).then(res => {
      callback(null, res)
    }).catch(err => {
      callback(err, null)
    })
  },
  getCompanyMembersById (companyId, callback) {
    return mainAxios.get(`company/${companyId}/users`).then(res => {
      callback(null, res)
    }).catch(err => {
      callback(err, null)
    })
  },
  getProfile (callback) {
    return mainAxios.get('profile').then(res => {
      callback(null, res)
    }).catch(err => {
      callback(err, null)
    })
  },
  getProfileById (id, callback) {
    return mainAxios.get('profile/' + id).then(res => {
      callback(null, res)
    }).catch(err => {
      callback(err, null)
    })
  },
  verifyEmailLogin (token, callback) {
    return mainAxios.get(`verify/email?token=${token}`).then(res => {
      callback(null, res.data)
    }).catch(err => {
      callback(err, false)
    })
  },
  authLoginStart (nin, callback) {
    return mainAxios.put('login/start', nin).then(res => {
      callback(null, res)
    }).catch(err => {
      callback(err, null)
    })
  },
  authLoginCollect (payload, callback) {
    return mainAxios.put('login/qr/collect', payload).then(res => {
      callback(null, res)
    }).catch(err => {
      callback(err, null)
    })
  },
  authLoginMobileCollect (payload, callback) {
    return mainAxios.put('login/collect', payload).then(res => {
      callback(null, res)
    }).catch(err => {
      callback(err, null)
    })
  },
  authRegister (registration, callback) {
    return mainAxios.put('register', registration).then(res => {
      callback(null, res)
    }).catch(err => {
      callback(err, null)
    })
  },
  authRegisterStart (payload, callback) {
    return mainAxios.put('register/qr/start', payload).then(res => {
      callback(null, res)
    }).catch(err => {
      callback(err, null)
    })
  },
  authRegisterCollect (payload, callback) {
    return mainAxios.put('register/qr/collect', payload).then(res => {
      callback(null, res)
    }).catch(err => {
      callback(err, null)
    })
  },
  authRegisterMobileStart (payload, callback) {
    return mainAxios.put('register/start', payload).then(res => {
      callback(null, res)
    }).catch(err => {
      callback(err, null)
    })
  },
  authRegisterMobileCollect (payload, callback) {
    return mainAxios.put('register/collect', payload).then(res => {
      callback(null, res)
    }).catch(err => {
      callback(err, null)
    })
  },
  checkhasActiveSubscription (callback) {
    return mainAxios.get('check/subscription').then(res => {
      callback(null, res)
    }).catch(err => {
      callback(err, null)
    })
  },
  logout (callback) {
    return mainAxios.get('logout').then(res => {
      callback(null, res.data)
    }).catch(err => {
      callback(err, null)
    })
  },
  editUser (user, callback) {
    return mainAxios.post('user', user).then(res => {
      callback(null, res)
    }, err => {
      callback(err, null)
    })
  },
  updateSearchable (isSearchable, callback) {
    if (isSearchable) {
      return mainAxios.put('profile/hide', isSearchable).then(res => {
        callback(null, res)
      }, err => {
        callback(err, null)
      })
    } else {
      return mainAxios.put('profile/show', isSearchable).then(res => {
        callback(null, res)
      }, err => {
        callback(err, null)
      })
    }
  },
  changeEmail (email, callback) {
    return mainAxios.post('user/settings/email', email).then(res => {
      callback(null, res)
    }, err => {
      callback(err, null)
    })
  },
  resendEmail (callback) {
    return mainAxios.post('user/settings/email/resend').then(res => {
      callback(null, res)
    }, err => {
      callback(err, null)
    })
  },
  setPaymentDetails: function (token, cb) {
    return mainAxios.post('store/ipu', token).then(res => {
      cb(null, res)
    }).catch(err => {
      cb(err, null)
    })
  },
  addPaymentCard (token, cb) {
    return mainAxios.post('payment/cards', token).then(res => {
      cb(null, res)
    }).catch(err => {
      cb(err, null)
    })
  },
  removeAccount (confirmation, callback) {
    return mainAxios.post('user/delete', { confirmation }).then(res => {
      callback(null, res)
    }).catch(err => {
      callback(err, null)
    })
  },
  getUser (callback) {
    return mainAxios.get('user').then(res => {
      callback(null, res)
    }).catch(err => {
      console.error(err.response)
      callback(err, null)
    })
  }
}

export { ApiService, mainAxios, authAxios }
