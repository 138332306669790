import { ApiService } from '@/common/api.service'
import { getInitialSearchFilters, getInitialState } from './index'
import { CLEAR_SEARCH_FILTERS, LOADING, PURGE_AUTH, RESET_STATE, SET_AUTH, SET_COMPANIES, SET_COMPANY_DETAILS, SET_COMPANY_INVITE_LINK, SET_COMPANY_MEMBERS, SET_COMPLETION, SET_CONTRACTS, SET_ERROR, SET_PHONE, SET_PICTURE, SET_PRODUCTS, SET_PROFILE, SET_PUBLIC_PROFILE, SET_QR_DATA_URL, SET_SCROLL_POSITION, SET_SEARCH_FILTERS, SET_SHOULD_CONTINUE_REFRESH, SET_USER } from './mutations.type'
import jwtService from '@/common/jwt.service'

export const mutations = {
  [SET_QR_DATA_URL] (state, qrcodeUrl) {
    state.qrcodeUrl = qrcodeUrl
  },
  [SET_SCROLL_POSITION] (state, position) {
    state.searchScrollPosition = position
  },
  [SET_COMPLETION] (state, { completion }) {
    state.profile.completion = completion
  },
  [LOADING] (state, isLoading) {
    if (isLoading) {
      state.refCount++
      state.isLoading = true
    } else if (state.refCount > 0) {
      state.refCount--
      state.isLoading = (state.refCount > 0)
    }
  },
  [CLEAR_SEARCH_FILTERS] (state) {
    Object.assign(state.searchFilters, getInitialSearchFilters())
  },
  setVerified (state) {
    state.user.verified = true
  },
  setHidden (state, hidden) {
    state.profile.hidden = hidden
  },
  setHasActiveSubscription (state, res) {
    state.user.isPremium = res.isPremium
    state.user.isAdmin = res.isAdmin
  },
  updatePaymentDetails (state, details) {
    state.paymentDetails = details
  },
  updateSubscription (state, bool) {
    state.isPremium = bool
  },
  updatePurchaseSubscription (state, subscription) {
    state.subscriptionPurchase = subscription
  },
  [SET_SHOULD_CONTINUE_REFRESH] (state, value) {
    state.shouldContinueRefresh = value
  },
  [SET_PICTURE] (state, picture) {
    state.user.picture.url = picture
    state.profile.picture.url = picture
  },
  [SET_PHONE] (state, user) {
    state.phone = user.phone
  },
  [SET_COMPANY_DETAILS] (state, companyDetails) {
    state.companyDetails = companyDetails
  },
  [SET_COMPANY_MEMBERS] (state, members) {
    state.companyMembers = members
  },
  [SET_COMPANY_INVITE_LINK] (state, link) {
    state.companyInviteLink = link
  },
  [SET_CONTRACTS] (state, data) {
    state.contracts = data
  },
  [SET_PRODUCTS] (state, products) {
    state.products = products
  },
  [SET_COMPANIES] (state, data) {
    state.adminForCompanies = data.adminForCompanies
    state.memberInCompanies = data.memberInCompanies
  },
  [SET_PROFILE] (state, profile) {
    if (Object.keys(profile).length !== 0) {
      state.profile = profile
    }
  },
  [SET_PUBLIC_PROFILE] (state, profile) {
    state.publicProfile = profile
  },
  [SET_AUTH] (state) {
    ApiService.setHeader()
    state.isAuthenticated = true
  },
  [SET_USER] (state, user) {
    state.isAdmin = user?.subscriptions?.isAdmin
    state.isPremium = user?.subscriptions?.isPremium
    state.user = user
  },
  [PURGE_AUTH] (state) {
    jwtService.destroyToken()
    ApiService.deleteHeader()
    state.isAuthenticated = false
  },
  [RESET_STATE] (state) {
    Object.assign(state, getInitialState())
  },
  [SET_ERROR] (state, error) {
    state.errors = error
  },
  [SET_SEARCH_FILTERS] (state, searchFilters) {
    state.searchFilters = searchFilters
  }
}
