export const getLaunchUrlByDevice = (autoStartToken, redirectUrl) => {
    // Add the #initiated=true fragment to help identify the returning tab
    redirectUrl += "#initiated=true";
    let baseUrl = "https://app.bankid.com/?autostarttoken="; // Default for most devices

    // Helper function to replace URL scheme
    const replaceScheme = (scheme, url) => `${scheme}://${url.replace(/^https?:\/\//, "")}`;

    // Adjust URL scheme based on the device and browser
    if (isSafariOniOS()) {
        baseUrl = "bankid:///?autostarttoken="; // Safari on iOS will open BankID app directly
    } else if (isChromeOnIOS()) {
        redirectUrl = replaceScheme("googlechrome", redirectUrl); // Custom URL for Chrome on iOS
    } else if (isFirefoxOnIOS()) {
        redirectUrl = replaceScheme("firefox", redirectUrl); // Custom URL for Firefox on iOS
    }

    return `${baseUrl}${autoStartToken}&redirect=${encodeURIComponent(redirectUrl)}`;
};

const isMobileDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|ipad|iphone|ipod/i.test(userAgent);
};

const isSafariOniOS = () => {
    const userAgent = navigator.userAgent;
    return /iP(ad|od|hone).*Safari/i.test(userAgent) && !/CriOS|Chrome/i.test(userAgent);
};

const isChromeOnIOS = () => {
    return /CriOS/i.test(navigator.userAgent);
};

const isFirefoxOnIOS = () => {
    return /FxiOS/i.test(navigator.userAgent);
};

const isChromeOnAndroid = () => {
    const userAgent = navigator.userAgent;
    return /Android/i.test(userAgent) && /Chrome/i.test(userAgent);
};

export const isAnyMobileDevice = () => isMobileDevice() || isSafariOniOS() || isChromeOnIOS() || isFirefoxOnIOS() || isChromeOnAndroid();