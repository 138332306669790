import { format, formatDistance, isAfter, addYears, subYears, parseISO } from 'date-fns'

const formatDate = {
  full (date) {
    return format(parseISO(date), 'yyyy-MM-dd HH:mm')
  },
  basic (date) {
    return format(date, 'yyyy-MM-dd')
  },
  basicISO (date) {
    return format(parseISO(date), 'yyyy-MM-dd')
  },
  time (date) {
    return format(parseISO(date), 'HH:mm')
  },
  profile (date) {
    return format(parseISO(date), 'MMM yyyy')
  },
  ll (date) {
    return format(parseISO(date), 'MMM dd, yyyy')
  },
  terms (date) {
    return format(parseISO(date), 'MMMM dd, yyyy')
  },
  addYears (date, number) {
    return format(addYears(parseISO(date), number), 'yyyy-MM-dd')
  },
  subYears (date, number) {
    return format(subYears(date, number), 'yyyy')
  },
  dayMonth (date) {
    return format(parseISO(date), 'ddd, MMM D - yyyy')
  },
  fromNow (date) {
    return formatDistance(parseISO(date))
  },
  isAfterToday (date) {
    return isAfter(parseISO(date), new Date())
  },
  formatYear (months) {
    const years = Math.floor(months / 12)
    if (years === 1) {
      return years + ' yr'
    } else {
      return years + ' yrs'
    }
  },
  formatRemainingMonths (months) {
    if (months === null || months === undefined) {
      return ''
    } else if (months < 12) {
      if (months === 1) {
        return months + ' mo'
      } else {
        return months + ' mos'
      }
    } else {
      if (months % 12 === 1) {
        return (months % 12) + ' mo'
      } else {
        return (months % 12) + ' mos'
      }
    }
  },
  yearOnly (date) {
    return format(parseISO(date), 'yyyy')
  }
}

export { formatDate }
