import { ApiService } from '@/common/api.service.js'
import {
  ADD_PAYMENT_CARD,
  AUTH_LOGIN_COLLECT,
  AUTH_LOGIN_START,
  AUTH_REFRESH_QRCODE,
  AUTH_REFRESH_REGISTER_QRCODE,
  AUTH_REGISTER_COLLECT,
  AUTH_REGISTER_START,
  AUTH_REGISTER,
  CHANGE_EMAIL,
  CHANGE_PAYMENT_DETAILS,
  CHANGE_PICTURE,
  CHANGE_SEARCHABLE,
  CHECK_ACTIVE_SUBSCRIPTION,
  CHECK_AUTH,
  CLEAR_SEARCH_FILTERS,
  DELETE_ACCOUNT,
  EDIT_USER,
  GET_COMPANIES,
  GET_COMPANY_DETAILS,
  GET_COMPANY_INVITE_LINK,
  GET_COMPANY_MEMBERS,
  GET_CONTRACTS,
  GET_PRODUCTS,
  GET_PROFILE_BY_ID,
  GET_PROFILE,
  GET_USER,
  LOGOUT,
  RESEND_EMAIL,
  SAVE_COMPANY_DETAILS,
  UPDATE_COMPLETION,
  UPDATE_SCROLL_POSITION,
  UPDATE_SEARCH_FILTERS,
  VERIFY_EMAIL,
  AUTH_REGISTER_MOBILE_COLLECT,
  AUTH_REGISTER_MOBILE_START,
  AUTH_LOGIN_MOBILE_COLLECT
} from './actions.type.js'
import { PURGE_AUTH, RESET_STATE, SET_AUTH, SET_COMPANIES, SET_COMPANY_DETAILS, SET_COMPANY_INVITE_LINK, SET_COMPANY_MEMBERS, SET_COMPLETION, SET_CONTRACTS, SET_EMAIL, SET_ERROR, SET_LOCATIONS, SET_PHONE, SET_PICTURE, SET_PRODUCTS, SET_PROFILE, SET_PUBLIC_PROFILE, SET_QR_DATA_URL, SET_SCROLL_POSITION, SET_SEARCH_FILTERS, SET_USER } from './mutations.type.js'
import jwtService from '@/common/jwt.service.js'

const MAX_RETRIES = 10

export const actions = {
  [GET_USER] (context) {
    return new Promise((resolve, reject) => {
      ApiService.getUser(function (err, res) {
        if (err) {
          reject(err)
          context.commit(SET_ERROR, err.response)
        } else {
          context.commit(SET_USER, res.data.user)
          resolve(res)
        }
      })
    })
  },
  [CHECK_AUTH] (context) {
    const token = jwtService.getToken()
    if (token) {
      return jwtService.checkTokenExpired(function (err, res) {
        if (err) {
          return Promise.reject(err)
        }
        if (res && res.tokenExpired === true) {
          context.commit(RESET_STATE)
          context.commit(PURGE_AUTH)
          // TODO send to login with "expired" message
          return Promise.resolve(res)
        }
        ApiService.setHeader()
        return new Promise((resolve, reject) => {
          ApiService.getUser(function (err, res) {
            if (err) {
              reject(err)
              context.commit(SET_ERROR, err.response)
              context.commit(RESET_STATE)
              context.commit(PURGE_AUTH)
            } else {
              context.commit(SET_USER, res.data.user)
              resolve(res)
            }
          })
        })
      })
    } else {
      context.commit(RESET_STATE)
      context.commit(PURGE_AUTH)
    }
  },
  [VERIFY_EMAIL] (context, token) {
    return new Promise((resolve, reject) => {
      ApiService.verifyEmailLogin(token, function (err, res) {
        if (err) reject(err)
        else {
          context.commit('setVerified')
          setTimeout(function () {
            resolve(res)
          }, 100)
        }
      })
    })
  },
  [CHANGE_SEARCHABLE] (context, isSearchable) {
    return new Promise((resolve, reject) => {
      ApiService.updateSearchable(!isSearchable, function (err, res) {
        if (err) reject(err)
        else {
          context.commit('setHidden', res.data.profile.hidden)
          setTimeout(function () {
            resolve(res)
          }, 100)
        }
      })
    })
  },
  [AUTH_REGISTER] (context, nin) {
    return new Promise((resolve, reject) => {
      ApiService.authRegister(nin, function (err, res) {
        if (err) reject(err)
        else {
          context.dispatch(AUTH_REGISTER_START)
          resolve(res)
        }
      })
    })
  },
  [AUTH_REGISTER_START] (context, registration) {
    return new Promise((resolve, reject) => {
      ApiService.authRegisterStart(registration, function (err, res) {
        if (err) reject(err)
        else {
          resolve(res)
        }
      })
    })
  },
  [AUTH_REGISTER_COLLECT] (context, orderRef) {
    return new Promise((resolve, reject) => {
      function collect () {
        const payload = {
          qrStartToken: orderRef.qrStartToken,
          orderTime: orderRef.orderTime
        }
        ApiService.authRegisterCollect(payload, function (err, res) {
          if (err) {
            reject(err)
          } else if (res.data.status === 'complete') {
            jwtService.saveToken(res.data.token)
            context.commit(SET_AUTH)
            context.dispatch(GET_USER).then((res) => {
              resolve(res)
            }).catch((err) => {
              reject(err)
            })
          } else {
            setTimeout(collect, 1000)
          }
        })
      }
      collect()
    })
  },
  [AUTH_REGISTER_MOBILE_START] (context, registration) {
    return new Promise((resolve, reject) => {
      ApiService.authRegisterMobileStart(registration, function (err, res) {
        if (err) reject(err)
        else {
          resolve(res)
        }
      })
    })
  },
  [AUTH_REGISTER_MOBILE_COLLECT] (context, payload) {
    return new Promise((resolve, reject) => {
      function collect () {
        ApiService.authRegisterMobileCollect(payload, function (err, res) {
          // window.alert('Register collect response: ' + JSON.stringify(res ? res.data : err));
          if (err) {
            reject(err)
          } else if (res.data.status === 'complete') {
            jwtService.saveToken(res.data.token)
            context.commit(SET_AUTH)
            context.dispatch(GET_USER).then((user) => {
              resolve(res.data)
            }).catch((err) => {
              reject(err)
            })
          } else if (res.data.status === 'userNotFound') {
            resolve(res)
          } else {
            retryCount++;
            if (retryCount < MAX_RETRIES) {
              setTimeout(collect, 1000);
            } else {
              reject(new Error('Maximum retries reached'));
            }
          }
        })
      }
      collect()
    })
  },
  [AUTH_LOGIN_START] (context, nin) {
    return new Promise((resolve, reject) => {
      ApiService.authLoginStart(nin, function (err, res) {
        if (err) reject(err)
        else {
          resolve(res)
        }
      })
    })
  },
  [AUTH_LOGIN_MOBILE_COLLECT] (context, orderRef) {
    return new Promise((resolve, reject) => {
      let retryCount = 0;
      function collect () {
        ApiService.authLoginMobileCollect(orderRef, function (err, res) {
          // window.alert('Login collect response: ' + JSON.stringify(res ? res.data : err));
          if (err) {
            reject(err)
          } else if (res.data.status === 'complete') {
            jwtService.saveToken(res.data.token)
            context.commit(SET_AUTH)
            context.dispatch(GET_USER).then((user) => {
              resolve(res.data)
            }).catch((err) => {
              reject(err)
            })
          } else if (res.data.status === 'userNotFound') {
            resolve(res)
          } else {
            retryCount++;
            if (retryCount < MAX_RETRIES) {
              setTimeout(collect, 1000);
            } else {
              reject(new Error('Maximum retries reached'));
            }
          }
        })
      }
      collect()
    })
  },
  [AUTH_LOGIN_COLLECT] (context, orderRef) {
    return new Promise((resolve, reject) => {
      function collect () {
        ApiService.authLoginCollect(orderRef, function (err, res) {
          if (err) {
            reject(err)
          } else if (res.data.status === 'complete') {
            jwtService.saveToken(res.data.token)
            context.commit(SET_AUTH)
            context.dispatch(GET_USER).then((res) => {
              resolve(res)
            }).catch((err) => {
              reject(err)
            })
          } else if (res.data.status === 'userNotFound') {
            resolve(res)
          } else {
            setTimeout(collect, 1000)
          }
        })
      }
      collect()
    })
  },
  [AUTH_REFRESH_QRCODE] (context, payload) {
    let intervalId = null

    return new Promise((resolve, reject) => {
      function refreshQRCode () {
        if (!context.state.shouldContinueRefresh) {
          clearInterval(intervalId)
          return
        }

        ApiService.authLoginCollect(payload, function (err, res) {
          if (err) {
            clearInterval(intervalId)
            reject(err)
          } else if (res.data.status === 'complete' && res.data.token) {
            clearInterval(intervalId)
            jwtService.saveToken(res.data.token)
            context.commit(SET_AUTH, { token: res.data.token, status: res.data.status })
            resolve({ token: res.data.token, status: res.data.status })
          } else if (res.data.status === 'userNotFound') {
            clearInterval(intervalId)
            resolve(res)
          } else {
            context.commit(SET_QR_DATA_URL, res.data.qrcodeUrl)
          }
        })
      }

      intervalId = setInterval(refreshQRCode, 1000)
    })
  },
  [AUTH_REFRESH_REGISTER_QRCODE] (context, payload) {
    let intervalId = null

    return new Promise((resolve, reject) => {
      function refreshQRCode () {
        if (!context.state.shouldContinueRefresh) {
          clearInterval(intervalId)
          return
        }

        ApiService.authRegisterCollect(payload, function (err, res) {
          if (err) {
            clearInterval(intervalId)
            reject(err)
          } else if (res.data.status === 'complete' && res.data.token) {
            clearInterval(intervalId)
            jwtService.saveToken(res.data.token)
            context.commit(SET_AUTH, { token: res.data.token, status: res.data.status })
            resolve({ token: res.data.token, status: res.data.status })
          } else if (res.data.status === 'userNotFound') {
            clearInterval(intervalId)
            resolve(res)
          } else {
            context.commit(SET_QR_DATA_URL, res.data.qrcodeUrl)
          }
        })
      }

      intervalId = setInterval(refreshQRCode, 1000)
    })
  },
  [GET_COMPANY_INVITE_LINK] (context, companyId) {
    return new Promise((resolve, reject) => {
      ApiService.getCompanyInviteLink(companyId, function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_COMPANY_INVITE_LINK, res.data.link)
          resolve(res)
        }
      })
    })
  },
  [GET_COMPANY_MEMBERS] (context, companyId) {
    return new Promise((resolve, reject) => {
      ApiService.getCompanyMembersById(companyId, function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_COMPANY_MEMBERS, res.data.users)
          resolve(res)
        }
      })
    })
  },
  [GET_PRODUCTS] (context) {
    return new Promise((resolve, reject) => {
      ApiService.getProducts(function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_PRODUCTS, res.data.products)
          resolve(res)
        }
      })
    })
  },
  [GET_COMPANIES] (context) {
    return new Promise((resolve, reject) => {
      ApiService.getCompanies(function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_COMPANIES, res.data)
          resolve(res)
        }
      })
    })
  },
  [GET_CONTRACTS] (context) {
    return new Promise((resolve, reject) => {
      ApiService.getContracts(function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_CONTRACTS, res.data.contracts)
          resolve(res)
        }
      })
    })
  },
  [GET_PROFILE] (context) {
    return new Promise((resolve, reject) => {
      ApiService.getProfile(function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_PROFILE, res.data.profile)
          resolve(res)
        }
      })
    })
  },
  [GET_PROFILE_BY_ID] (context, id) {
    return new Promise((resolve, reject) => {
      ApiService.getProfileById(id, function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_PUBLIC_PROFILE, res.data.profile)
          resolve(res)
        }
      })
    })
  },
  [SET_LOCATIONS] (context, locations) {
    context.commit(SET_LOCATIONS, locations)
  },
  [CHECK_ACTIVE_SUBSCRIPTION] (context) {
    return new Promise((resolve, reject) => {
      ApiService.checkhasActiveSubscription(function (err, res) {
        if (err) reject(err)
        else {
          context.commit('setHasActiveSubscription', res.data)
          setTimeout(function () {
            resolve(res)
          }, 100)
        }
      })
    })
  },
  [LOGOUT] (context) {
    context.commit(RESET_STATE)
    context.commit(PURGE_AUTH)
  },
  [CLEAR_SEARCH_FILTERS] (context) {
    context.commit(CLEAR_SEARCH_FILTERS)
  },
  [UPDATE_SEARCH_FILTERS] (context, searchFilters) {
    context.commit(SET_SEARCH_FILTERS, searchFilters)
  },
  [EDIT_USER] (context, user) {
    return new Promise((resolve, reject) => {
      ApiService.editUser(user, function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_PHONE, user)
          setTimeout(function () {
            resolve(res)
          }, 100)
        }
      })
    })
  },
  [SAVE_COMPANY_DETAILS] (context, companyDetails) {
    return new Promise((resolve, reject) => {
      ApiService.saveCompanyDetails(companyDetails, function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_COMPANY_DETAILS, companyDetails)
          setTimeout(function () {
            resolve(res)
          }, 100)
        }
      })
    })
  },
  [GET_COMPANY_DETAILS] (context, companyId) {
    return new Promise((resolve, reject) => {
      ApiService.getCompanyDetails(companyId, function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_COMPANY_DETAILS, res.data.company)
          setTimeout(function () {
            resolve(res)
          }, 100)
        }
      })
    })
  },
  [CHANGE_EMAIL] (context, emailData) {
    return new Promise((resolve, reject) => {
      ApiService.changeEmail(emailData, function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_EMAIL, emailData)
          setTimeout(function () {
            resolve(res)
          }, 100)
        }
      })
    })
  },
  [RESEND_EMAIL] (context) {
    return new Promise((resolve, reject) => {
      ApiService.resendEmail(function (err, res) {
        if (err) reject(err)
        else {
          resolve(res)
        }
      })
    })
  },
  [ADD_PAYMENT_CARD] (context, token) {
    return new Promise((resolve, reject) => {
      ApiService.addPaymentCard(token, (err, data) => {
        if (err) reject(err)
        else {
          resolve(data)
        }
      })
    })
  },
  [CHANGE_PAYMENT_DETAILS] (context, token) {
    return new Promise((resolve, reject) => {
      ApiService.setPaymentDetails(token, (err, data) => {
        if (err) reject(err)
        else {
          resolve(data)
        }
      })
    })
  },
  [DELETE_ACCOUNT] (context, confirmation) {
    return new Promise((resolve, reject) => {
      ApiService.removeAccount(confirmation, function (err, res) {
        if (err) reject(err)
        else {
          resolve(res)
        }
      })
    })
  },
  [CHANGE_PICTURE] (context, profile) {
    context.commit(SET_PICTURE, profile.picture.url)
    context.commit(SET_COMPLETION, profile.completion)
  },
  [UPDATE_COMPLETION] (context, completion) {
    context.commit(SET_COMPLETION, completion)
  },
  [UPDATE_SCROLL_POSITION] (context, position) {
    context.commit(SET_SCROLL_POSITION, position)
  }
}
