<template>
  <div
    class="d-flex flex-column animate__animated animate__fadeIn"
    style="width: 100%; max-width: 420px"
  >
    <div class="d-flex justify-center mt-4 mb-2">
      <img
        height="64"
        width="64"
        alt="BankID logo"
        src="https://static.kivra.com/assets/logo/bankid-dark.png"
      >
    </div>
    <div class="text-center text-h5 mt-6 mb-10">
      Legitimera med BankID
    </div>
    <div class="d-flex justify-center align-center mb-10">
      <div
        class="d-flex justify-center align-center"
        style="width: 240px; height: 240px"
      >
        <v-progress-circular
          v-if="!qrcodeUrl"
          indeterminate
          color="secondary"
        />
        <div
          v-if="qrcodeUrl"
          class="box"
        >
          <QrcodeSvg
            :size="200"
            :value="qrcodeUrl"
            level="H"
          />
        </div>
      </div>
    </div>
    <ol class="mb-8 text-body-2">
      <li>Öppna BankID-appen och klicka på QR-ikonen.</li>
      <li>Rikta kameran mot QR-koden som visas här på skärmen.</li>
    </ol>
    <v-btn
      color="#265973"
      class="secondary--text d-flex mb-4"
      rounded
      x-large
      text
      @click="onCancelLogin()"
    >
      Avbryt
    </v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  AUTH_REFRESH_QRCODE,
  AUTH_REFRESH_REGISTER_QRCODE,
  GET_USER,
  SET_SHOULD_CONTINUE_REFRESH,
} from "@/store/actions.type";

import QrcodeSvg from "qrcode.vue";

export default {
  name: "BankidAuth",
  components: {
    QrcodeSvg,
  },
  props: {
    qrcodeType: {
      type: String,
      default: "login",
    },
    registration: {
      type: Object,
      required: false,
      default: () => ({
        terms: true,
        email: "",
        userType: "consultant",
        phone: "",
      }),
    },
  },
  computed: {
    ...mapState(["qrcodeUrl"]),
  },
  watch: {
    registration: {
      handler(newVal) {
        if (this.qrcodeType === "register" && newVal) {
          this.initializeQRCode();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  beforeMount() {
    if (this.qrcodeType === "login") {
      this.initializeQRCode();
    }
  },
  methods: {
    async initializeQRCode() {
      try {
        let res;
        if (this.qrcodeType === "login") {
          res = await this.$http.get("login/qr/start");
        }
        if (this.qrcodeType === "register") {
          res = await this.$http.put("register/qr/start", this.registration);
        }
        this.$store.commit(SET_SHOULD_CONTINUE_REFRESH, true);
        // console.log("this.qrcodeType", this.qrcodeType);
        // console.log("res.data", res.data);
        this.refreshQRCodeEverySecond(res.data);
      } catch (err) {
        // console.log("err initializeQRCode", err.response);
        this.onCancelLogin(err);
        this.$root.$emit("errorMessage", err.response);
      }
    },
    async refreshQRCodeEverySecond(payload) {
      try {
        const res = await this.$store.dispatch(
          this.qrcodeType === "login"
            ? AUTH_REFRESH_QRCODE
            : AUTH_REFRESH_REGISTER_QRCODE,
          payload
        );
        // console.log("res", res);
        if (res.token && res.status === "complete") {
          this.$gtm.trackEvent({
            category: 'Growth',
            action: 'New-signup',
            label: 'New signup',
            value: 0
          })
          this.$store.dispatch(GET_USER);
          this.$router.push({ name: "dashboard" });
        }
        if (res.data.status === "userNotFound") {
          this.onCancelLogin();
          const error = {
            message: this.$t("Validation.user_not_found"),
          }
          this.$root.$emit("errorMessage", error);
        }
      } catch (err) {
        // console.log("err refreshQRCodeEverySecond", err);
        this.onCancelLogin(err);
        this.$root.$emit("errorMessage", err.response);
      }
    },
    onCancelLogin(err) {
      this.$store.commit(SET_SHOULD_CONTINUE_REFRESH, false);
      if (err) {
        this.$emit("cancel-login", err);
      } else {
        this.$emit("cancel-login");
      }
    },
  },
};
</script>

<style scoped>
/* Add any custom styles here */
.theme--dark.v-tabs-items,
.theme--light.v-tabs-items {
  background-color: transparent !important;
}

.box {
  --b: 5px; /* thickness of the border */
  --c: white; /* color of the border */
  --w: 20px; /* width of border */
  --r: 5px; /* radius */
  background: white;
  padding: var(--b); /* space for the border */
  border-radius: 5px;
  width: 240px;
  height: 240px;
  position: relative;
  /*Irrelevant code*/
  box-sizing: border-box;
  margin: 5px;
  display: inline-flex;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
